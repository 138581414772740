import React, { useEffect } from "react";
import ShakaPlayer from 'shaka-player-react';
import 'shaka-player/dist/controls.css';

const Video = () => {
  return (
    <div>
      <ShakaPlayer autoPlay src="https://node-playground.compliance.monster/testvid.m3u8" />
    </div>
  );
};

export default Video;
