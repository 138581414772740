import React, { useEffect, useState } from "react";
import ImageMapper from "react-img-mapper";
import areas from "./areas";
import image from "./museum_poster.png";
import { useHistory } from "react-router-dom";
import useMousePosition from "./useMousePosition";

const ExplorerMode = () => {
  const URL = image;
  const MAP = {
    name: "my-map",
    areas: areas,
  };
  const [description, setDescription] = useState("demo description");
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const [pointerVisibile, setPointerVisibile] = useState(true);
  const [pointerLocation, setPointerLocation] = useState({});
  const [popStyle, setPopStyle] = useState({});
  const { x, y } = useMousePosition();
  const [flag, setFlag] = useState(0);
  let history = useHistory();

  useEffect(() => {
    let cornerElement = false;
    if (
      description === "Hausbock" ||
      description === "Küchenschabe" ||
      description === "Hausmutter" ||
      description === "Mehlkäfer"
    ) {
      cornerElement = true;
    }
    if (descriptionVisible && flag === 0) {
      if (cornerElement) {
        if (description === "Mehlkäfer") {
          setPopStyle({
            zIndex: 9,
            left: x - 60 + "px",
            top: y - 80 + "px",
          });
        } else {
          setPopStyle({
            zIndex: 9,
            left: x - 100 + "px",
            top: y - 80 + "px",
          });
        }
      } else {
        setPopStyle({
          marginRight: 30,
          zIndex: 9,
          left: x + "px",
          top: y + "px",
        });
      }

      setFlag(1);
    }
  }, [x, y]);

  return (
    <div
      id="dialogContainer"
      style={{ position: "relative", backgroundColor: "white"}}
    >
      <div
        onClick={() => {
          history.push("/");
        }}
        style={{
          ...closeButton,
          ...roundButton,
        }}
      >
        <i className="fa fa-times"></i>
      </div>

      {descriptionVisible ? (
        <div
          onClick={() => {}}
          style={{
            ...popStyle,
            ...descriptionButton,
          }}
        >
          {description}
          <div
            onClick={() => {
              setDescriptionVisible(false);
            }}
            style={{
              ...closePopupButton,
              ...roundButton,
            }}
          >
            <i className="fa fa-times"></i>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* {pointerVisibile ? (
        <AnimatedCursor
          trailingSpeed={3}
          color="29, 78, 155"
          innerSize={5}
          outerSize={40}
        />
      ) : (
        <></>
      )} */}
      <div style={{ paddingLeft: 50, paddingTop: 40 }}>
        <ImageMapper
          fillColor="rgba(255, 255, 255, 0.0)"
          strokeColor="rgba(255, 255, 255, 0.0)"
          src={URL}
          map={MAP}
          responsive
          parentWidth={window.innerWidth - 100}
          onClick={(event) => {
            console.log(event);
            setFlag(0);
            setDescription(event.title);
            setDescriptionVisible(true);
          }}
        />
      </div>
    </div>
  );
};

const roundButton = {
  borderRadius: 50,
  boxShadow: "5px 5px 15px 5px rgba(0,0,0,0.2)",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  position: "absolute",
};

const descriptionButton = {
  backgroundColor: "#1d4e9b",
  opacity: 0.9,
  color: "white",
  marginTop: 10,
  padding: 20,
  borderRadius: 5,
  boxShadow: "5px 5px 15px 5px rgba(0,0,0,0.2)",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  position: "absolute",
};

const closePopupButton = {
  width: 30,
  height: 30,
  backgroundColor: "white",
  top: 0,
  right: 0,
  marginTop: -15,
  marginRight: -15,
  color: "red",
};

const closeButton = {
  width: 50,
  height: 50,
  backgroundColor: "white",
  top: 0,
  right: 0,
  marginTop: 20,
  marginRight: 30,
  color: "red",
  opacity: 0.9,
  zIndex: 9,
};

export default ExplorerMode;
