export default [
  {
    id: "1",
    title: "Steinmarder",
    shape: "poly",
    name: "1",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1773, 563, 1772, 823, 1763, 1002, 1729, 1237, 1627, 973, 1670, 915, 1693,
      749, 1673, 627, 1773, 563,
    ],
    polygon: [
      [1773, 563],
      [1772, 823],
      [1763, 1002],
      [1729, 1237],
      [1627, 973],
      [1670, 915],
      [1693, 749],
      [1673, 627],
      [1773, 563],
    ],
  },
  {
    id: "1a",
    title: "Steinmarder(mit Jungen)",
    shape: "poly",
    name: "1a",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1792, 170, 1750, 109, 1750, 33, 1793, 2, 1846, 20, 1877, 84, 1877, 119,
      1830, 154,
    ],
    polygon: [
      [1792, 170],
      [1750, 109],
      [1750, 33],
      [1793, 2],
      [1846, 20],
      [1877, 84],
      [1877, 119],
      [1830, 154],
    ],
  },
  {
    id: "2",
    title: "Wanderratte",
    shape: "poly",
    name: "2",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      520, 1627, 571, 1596, 696, 1614, 723, 1664, 849, 1648, 734, 1688, 676,
      1718, 581, 1721, 520, 1627,
    ],
    polygon: [
      [520, 1627],
      [571, 1596],
      [696, 1614],
      [723, 1664],
      [849, 1648],
      [734, 1688],
      [676, 1718],
      [581, 1721],
      [520, 1627],
    ],
  },
  {
    id: "3",
    title: "Hausratte",
    shape: "poly",
    name: "3",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1399, 1761, 1390, 1701, 1333, 1653, 1232, 1610, 1293, 1738, 1348, 1778,
      1399, 1761,
    ],
    polygon: [
      [1399, 1761],
      [1390, 1701],
      [1333, 1653],
      [1232, 1610],
      [1293, 1738],
      [1348, 1778],
      [1399, 1761],
    ],
  },
  {
    id: "4",
    title: "Mausohr",
    shape: "poly",
    name: "4",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      2123, 448, 2099, 444, 2088, 420, 2117, 368, 2144, 377, 2153, 435, 2123,
      448,
    ],
    polygon: [
      [2123, 448],
      [2099, 444],
      [2088, 420],
      [2117, 368],
      [2144, 377],
      [2153, 435],
      [2123, 448],
    ],
  },
  {
    id: "5",
    title: "Hausmaus",
    shape: "poly",
    name: "5",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [338, 1208, 362, 1185, 403, 1186, 420, 1219, 379, 1228, 338, 1208],
    polygon: [
      [338, 1208],
      [362, 1185],
      [403, 1186],
      [420, 1219],
      [379, 1228],
      [338, 1208],
    ],
  },
  {
    id: "6",
    title: "Hausspitzmaus",
    shape: "poly",
    name: "6",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      278, 1769, 259, 1756, 218, 1762, 208, 1786, 239, 1801, 260, 1794, 319,
      1789, 362, 1787, 380, 1773, 357, 1753, 320, 1754, 301, 1763, 278, 1769,
    ],
    polygon: [
      [278, 1769],
      [259, 1756],
      [218, 1762],
      [208, 1786],
      [239, 1801],
      [260, 1794],
      [319, 1789],
      [362, 1787],
      [380, 1773],
      [357, 1753],
      [320, 1754],
      [301, 1763],
      [278, 1769],
    ],
  },
  {
    id: "7",
    title: "Schleiereule",
    shape: "poly",
    name: "7",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      2477, 1244, 2436, 1241, 2405, 1187, 2350, 1125, 2460, 1028, 2543, 1016,
      2629, 1048, 2637, 1108, 2635, 1223, 2592, 1229, 2573, 1139, 2525, 1158,
      2552, 1227,
    ],
    polygon: [
      [2477, 1244],
      [2436, 1241],
      [2405, 1187],
      [2350, 1125],
      [2460, 1028],
      [2543, 1016],
      [2629, 1048],
      [2637, 1108],
      [2635, 1223],
      [2592, 1229],
      [2573, 1139],
      [2525, 1158],
      [2552, 1227],
    ],
  },
  {
    id: "8",
    title: "Turmfalke (Familie)",
    shape: "poly",
    name: "8",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1303, 803, 1184, 855, 1148, 918, 1091, 909, 1067, 860, 939, 817, 1009,
      924, 854, 994, 705, 1145, 951, 1137, 1050, 1080, 1212, 1059, 1190, 989,
      1222, 894, 1301, 803,
    ],
    polygon: [
      [1303, 803],
      [1184, 855],
      [1148, 918],
      [1091, 909],
      [1067, 860],
      [939, 817],
      [1009, 924],
      [854, 994],
      [705, 1145],
      [951, 1137],
      [1050, 1080],
      [1212, 1059],
      [1190, 989],
      [1222, 894],
      [1301, 803],
    ],
  },
  {
    id: "9",
    title: "Dohle",
    shape: "poly",
    name: "9",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      2533, 579, 2413, 502, 2395, 548, 2494, 664, 2467, 722, 2603, 716, 2583,
      665, 2666, 608, 2671, 543, 2533, 579,
    ],
    polygon: [
      [2533, 579],
      [2413, 502],
      [2395, 548],
      [2494, 664],
      [2467, 722],
      [2603, 716],
      [2583, 665],
      [2666, 608],
      [2671, 543],
      [2533, 579],
    ],
  },
  {
    id: "10",
    title: "Mauersegler",
    shape: "poly",
    name: "10",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1225, 945, 1234, 1045, 1265, 1058, 1253, 1025, 1269, 976, 1248, 941, 1225,
      945,
    ],
    polygon: [
      [1225, 945],
      [1234, 1045],
      [1265, 1058],
      [1253, 1025],
      [1269, 976],
      [1248, 941],
      [1225, 945],
    ],
  },
  {
    id: "11",
    title: "Mehlschwalbe",
    shape: "poly",
    name: "11",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1059, 679, 1032, 663, 1012, 683, 1003, 742, 1011, 772, 1024, 771, 1079,
      812, 1098, 812, 1117, 738, 1102, 704, 1078, 708, 1059, 679,
    ],
    polygon: [
      [1059, 679],
      [1032, 663],
      [1012, 683],
      [1003, 742],
      [1011, 772],
      [1024, 771],
      [1079, 812],
      [1098, 812],
      [1117, 738],
      [1102, 704],
      [1078, 708],
      [1059, 679],
    ],
  },

  {
    id: "12",
    title: "Hausrotschwanz",
    shape: "poly",
    name: "12",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      736, 635, 717, 652, 735, 690, 772, 703, 804, 733, 812, 723, 802, 695, 753,
      649, 736, 635,
    ],
    polygon: [
      [736, 635],
      [717, 652],
      [735, 690],
      [772, 703],
      [804, 733],
      [812, 723],
      [802, 695],
      [753, 649],
      [736, 635],
    ],
  },
  {
    id: "13",
    title: "Haussperling",
    shape: "poly",
    name: "13",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1184, 495, 1173, 511, 1195, 548, 1212, 565, 1233, 573, 1269, 534, 1254,
      490, 1226, 515, 1184, 495,
    ],
    polygon: [
      [1184, 495],
      [1173, 511],
      [1195, 548],
      [1212, 565],
      [1233, 573],
      [1269, 534],
      [1254, 490],
      [1226, 515],
      [1184, 495],
    ],
  },
  {
    id: "14",
    title: "Haubenlerche",
    shape: "poly",
    name: "14",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1161, 722, 1213, 676, 1225, 651, 1259, 661, 1244, 681, 1239, 707, 1222,
      713, 1190, 722, 1161, 722,
    ],
    polygon: [
      [1161, 722],
      [1213, 676],
      [1225, 651],
      [1259, 661],
      [1244, 681],
      [1239, 707],
      [1222, 713],
      [1190, 722],
      [1161, 722],
    ],
  },
  {
    id: "15",
    title: "Hornisse",
    shape: "poly",
    name: "15",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1527, 7, 1404, 34, 1385, 153, 1401, 254, 1465, 319, 1584, 326, 1663, 236,
      1635, 65, 1590, 4, 1527, 7,
    ],
    polygon: [
      [1527, 7],
      [1404, 34],
      [1385, 153],
      [1401, 254],
      [1465, 319],
      [1584, 326],
      [1663, 236],
      [1635, 65],
      [1590, 4],
      [1527, 7],
    ],
  },
  {
    id: "16",
    title: "Deutsche Wespe",
    shape: "poly",
    name: "16",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      598, 86, 545, 118, 558, 172, 601, 194, 652, 161, 654, 117, 598, 86,
    ],
    polygon: [
      [598, 86],
      [545, 118],
      [558, 172],
      [601, 194],
      [652, 161],
      [654, 117],
      [598, 86],
    ],
  },
  {
    id: "17",
    title: "Küchenschabe",
    shape: "poly",
    name: "17",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [161, 1952, 118, 1976, 203, 1976, 161, 1952],
    polygon: [
      [161, 1952],
      [118, 1976],
      [203, 1976],
      [161, 1952],
    ],
  },
  {
    id: "18",
    title: "Hausbock",
    shape: "poly",
    name: "18",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      2881, 1864, 2852, 1872, 2860, 1897, 2906, 1908, 2926, 1882, 2902, 1862,
      2881, 1864,
    ],
    polygon: [
      [2881, 1864],
      [2852, 1872],
      [2860, 1897],
      [2906, 1908],
      [2926, 1882],
      [2902, 1862],
      [2881, 1864],
    ],
  },
  {
    id: "19",
    title: "Kleidermotte",
    shape: "poly",
    name: "19",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [1920, 1694, 1920, 1740, 2007, 1740, 1982, 1694, 1920, 1694],
    polygon: [
      [1920, 1694],
      [1920, 1740],
      [2007, 1740],
      [1982, 1694],
      [1920, 1694],
    ],
  },
  {
    id: "20",
    title: "Hausmutter",
    shape: "poly",
    name: "20",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      2821, 620, 2851, 648, 2882, 633, 2881, 594, 2856, 600, 2822, 615, 2821,
      620,
    ],
    polygon: [
      [2821, 620],
      [2851, 648],
      [2882, 633],
      [2881, 594],
      [2856, 600],
      [2822, 615],
      [2821, 620],
    ],
  },

  {
    id: "21",
    title: "Kleiner Fuchs",
    shape: "poly",
    name: "21",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1292, 630, 1266, 640, 1280, 666, 1308, 674, 1321, 654, 1318, 620, 1292,
      630,
    ],
    polygon: [
      [1292, 630],
      [1266, 640],
      [1280, 666],
      [1308, 674],
      [1321, 654],
      [1318, 620],
      [1292, 630],
    ],
  },
  {
    id: "22",
    title: "Schmeißfliege",
    shape: "poly",
    name: "22",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1085, 1088, 1055, 1113, 1042, 1145, 1123, 1150, 1134, 1116, 1127, 1083,
      1085, 1088,
    ],
    polygon: [
      [1085, 1088],
      [1055, 1113],
      [1042, 1145],
      [1123, 1150],
      [1134, 1116],
      [1127, 1083],
      [1085, 1088],
    ],
  },
  {
    id: "24",
    title: "Florfliege",
    shape: "poly",
    name: "24",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [],
    polygon: [],
  },
  {
    id: "25",
    title: "Mehlkäfer",
    shape: "poly",
    name: "25",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [45, 1918, 31, 1952, 54, 1964, 84, 1941, 51, 1917, 45, 1918],
    polygon: [
      [45, 1918],
      [31, 1952],
      [54, 1964],
      [84, 1941],
      [51, 1917],
      [45, 1918],
    ],
  },
  {
    id: "26",
    title: "Battlaus",
    shape: "poly",
    name: "26",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [258, 986, 264, 1039, 294, 1065, 318, 1030, 285, 1002, 258, 986],
    polygon: [
      [258, 986],
      [264, 1039],
      [294, 1065],
      [318, 1030],
      [285, 1002],
      [258, 986],
    ],
  },
  {
    id: "27",
    title: "Marienkäfer",
    shape: "poly",
    name: "27",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      218, 1056, 222, 1089, 197, 1103, 175, 1080, 184, 1057, 207, 1060, 218,
      1056,
    ],
    polygon: [
      [218, 1056],
      [222, 1089],
      [197, 1103],
      [175, 1080],
      [184, 1057],
      [207, 1060],
      [218, 1056],
    ],
  },
  {
    id: "28",
    title: "Ohrwurm",
    shape: "poly",
    name: "28",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [9, 1752, 28, 1764, 18, 1787, 0, 1802, 0, 1762, 9, 1752],
    polygon: [
      [9, 1752],
      [28, 1764],
      [18, 1787],
      [0, 1802],
      [0, 1762],
      [9, 1752],
    ],
  },
  {
    id: "29",
    title: "Pelzkäfer",
    shape: "poly",
    name: "29",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1667, 1349, 1630, 1362, 1646, 1385, 1668, 1384, 1675, 1356, 1667, 1349,
    ],
    polygon: [
      [1667, 1349],
      [1630, 1362],
      [1646, 1385],
      [1668, 1384],
      [1675, 1356],
      [1667, 1349],
    ],
  },
  {
    id: "30",
    title: "Speckkäfer",
    shape: "poly",
    name: "30",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1669, 1472, 1644, 1489, 1652, 1519, 1679, 1527, 1684, 1501, 1683, 1479,
      1669, 1472,
    ],
    polygon: [
      [1669, 1472],
      [1644, 1489],
      [1652, 1519],
      [1679, 1527],
      [1684, 1501],
      [1683, 1479],
      [1669, 1472],
    ],
  },

  {
    id: "31",
    title: "Dörrobstmotte",
    shape: "poly",
    name: "31",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      680, 262, 652, 271, 636, 235, 666, 222, 699, 228, 699, 258, 680, 262,
    ],
    polygon: [
      [680, 262],
      [652, 271],
      [636, 235],
      [666, 222],
      [699, 228],
      [699, 258],
      [680, 262],
    ],
  },
  {
    id: "32",
    title: "Rotes Ordensband",
    shape: "poly",
    name: "32",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      930, 150, 888, 168, 913, 202, 927, 212, 977, 180, 963, 132, 930, 150,
    ],
    polygon: [
      [930, 150],
      [888, 168],
      [913, 202],
      [927, 212],
      [977, 180],
      [963, 132],
      [930, 150],
    ],
  },

  {
    id: "33",
    title: "Schwebfliege",
    shape: "poly",
    name: "33",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [],
    polygon: [],
  },
  {
    id: "34",
    title: "Heimchen",
    shape: "poly",
    name: "34",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1745, 1704, 1685, 1712, 1690, 1750, 1758, 1749, 1814, 1737, 1796, 1705,
      1753, 1702, 1745, 1704,
    ],
    polygon: [
      [1745, 1704],
      [1685, 1712],
      [1690, 1750],
      [1758, 1749],
      [1814, 1737],
      [1796, 1705],
      [1753, 1702],
      [1745, 1704],
    ],
  },
  {
    id: "35",
    title: "Weberknecht",
    shape: "poly",
    name: "35",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      812, 767, 774, 783, 773, 830, 795, 856, 858, 838, 866, 794, 839, 764, 812,
      767,
    ],
    polygon: [
      [812, 767],
      [774, 783],
      [773, 830],
      [795, 856],
      [858, 838],
      [866, 794],
      [839, 764],
      [812, 767],
    ],
  },
  {
    id: "36",
    title: "Winkelspinne",
    shape: "poly",
    name: "36",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [612, 1461, 584, 1437, 553, 1461, 565, 1482, 580, 1484, 612, 1461],
    polygon: [
      [612, 1461],
      [584, 1437],
      [553, 1461],
      [565, 1482],
      [580, 1484],
      [612, 1461],
    ],
  },
  {
    id: "37",
    title: "Schimmelpilz",
    shape: "poly",
    name: "37",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      856, 43, 799, 54, 757, 94, 862, 126, 964, 86, 1080, 65, 1070, 35, 963, 47,
      888, 38, 856, 43,
    ],
    polygon: [
      [856, 43],
      [799, 54],
      [757, 94],
      [862, 126],
      [964, 86],
      [1080, 65],
      [1070, 35],
      [963, 47],
      [888, 38],
      [856, 43],
    ],
  },
  {
    id: "38",
    title: "Wein",
    shape: "poly",
    name: "38",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [
      1306, 816, 1274, 839, 1275, 1044, 1334, 1052, 1328, 824, 1299, 832, 1306,
      816,
    ],
    polygon: [
      [1306, 816],
      [1274, 839],
      [1275, 1044],
      [1334, 1052],
      [1328, 824],
      [1299, 832],
      [1306, 816],
    ],
  },
  {
    id: "39",
    title: "Hornzahnmoos",
    shape: "poly",
    name: "39",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [1029, 508, 1017, 476, 938, 490, 938, 540, 1025, 531, 1029, 508],
    polygon: [
      [1029, 508],
      [1017, 476],
      [938, 490],
      [938, 540],
      [1025, 531],
      [1029, 508],
    ],
  },
  {
    id: "40",
    title: "Mauer-Kuchenflechte",
    shape: "poly",
    name: "40",
    //fillColor: "#001D4E9B",
    //strokeColor: "black",
    coords: [1132, 437, 1165, 468, 1208, 443, 1208, 424, 1153, 423, 1132, 437],
    polygon: [
      [1132, 437],
      [1165, 468],
      [1208, 443],
      [1208, 424],
      [1153, 423],
      [1132, 437],
    ],
  },
];
