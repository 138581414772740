import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
import store from "./redux/store";
import Video from "./pages/About/Video";
import Selector from "./pages/Selector/Selector";
import ExplorerMode from "./pages/ExplorerMode/ExplorerMode";

function App() {
  let persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Route exact path="/" component={Selector} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/explore" component={ExplorerMode} />
          <Route exact path="/about" component={Video} />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
