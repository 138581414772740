export const chapters = [
    {
      start: "00:00",
      end: "00:21",
      title: "Dachboden im Altbau",
      description:
        "Nur noch selten findet man solche leicht zugänglichen Dachböden, die einer ganzen Reihe von Tierarten Unterschlupf und Niststätte bieten. Vor allem ursprüngliche Felsbewohner oder Tierarten, die sich im Totholz entwickeln, können innen und an Gebäuden heimisch werden",
      description_en:
        "It is rare to find such easily accessible attics that offer shelter and nesting places for a whole range of animals, species that are originally cliff dwellers or those that develop in dead wood are most likely to adapt to life in and around buildings.",
    },
    {
      start: "00:22",
      end: "00:37",
      title: "Wanderratte und Hausratte",
      description:
        "Die Wanderratte und die Hausrate wurden beide nach Europa eingeschleppt. Die Hausratte wurde als pestüberträger bereits im Mittelalter bekämpft. Auch die Hausmaus, die hier links auf dem Getreidesack sitzt, wurde schon immer mit Fallen gefangen.",
      description_en:
        "The brown rat and the black rat were both brought to Europe from Asia. The latter was fought as a pest and carrier of the bubonic plague in the Middle Ages. The house mouse seen on the left sitting on the grain sack has always been combated with traps.",
    },
    {
      start: "00:38",
      end: "00:52",
      title: "Steinmarder",
      description:
        "Wer selber mal einen Steinmarder auf dem Dachboden hatte, wird wissen, wie lebhaft die Tiere in der Nacht rumpoltern. Hier trägt das Weibchen ein Hühnerei unversehrte Maul zu den beiden Jungtieren auf dem Boden.",
      description_en:
        "Anyone who has had a Martin under their roof knows how noisy these nocturnal predators can be. Here, the female Martin is carrying a chicken egg in her mouth to her young in the attic.",
    },
    {
      start: "00:53",
      end: "01:08",
      title: "Schleiereule",
      description:
        "Geräumige, ungestörte Dachböden werden gerne von der Schleiereule als Brutplatz ausgewählt.",
      description_en:
        "Large, undisturbed attics are a favorite nesting place for the barn owl. These avid mouse hunters used to chase rodents in the green lofts in the wintertime, helping the farmers to save their food stores",
    },
    {
      start: "01:09",
      end: "01:19",
      title: "Dohle",
      description:
        "Auch Dohlen gehen gerne im Gebäude, wo sie umfangreiches Nistmaterial eintragen. Hier schaut eine Dohle durch das rechte Fenster herein.",
      description_en:
        "Jackdaws also nest in buildings, carrying in much material for their nests. Here we can see a jackdaw looking through the window on the right.",
    },
    {
      start: "01:20",
      end: "01:59",
      title: "Turmfalke (Familie), Mauersegler",
      description:
        "Der Turmfalkeman bringt seinem Weibchen eine erbeutete Maus, worüber sie ganz erregt freut. Sie wird die Maus mit dem Schnabel zerkleinern und portionsweise an ihre dünnen Jungen verfüttern. Draußen jagen Mauersegler Insekten in der Luft . Ihr Kreischen hallt durch die Häuser.",
      description_en:
        "The male kestrel is bringing a mouse to his mate, who appears to be happy to receive it. She will cut up the mouse with her beak and feed the portions to her nestlings",
    },
    {
      start: "02:00",
      end: "02:57",
      title: "Mehlschwalben, Haussperlinge",
      description:
        "Mehlschwalben haben an der Wand des Nachbarhauses unmittelbar unter dem Dachvorsprung ihr Nest angelegt. Sie können sich gut an der Hauswand mit ihren Zehenkrallen festhalten. Vom Dach pfeifen die Haussperlinge die letzten Neuigkeiten",
      description_en:
        "House Martins have built their cup-shaped nest of mud on the wall under the eaves of the neighboring house. They can hold onto the wall with their claws. The house sparrows are whistling the latest news from the rooftop. Like many other species, they have moved into human settlements attracted by the abundance of food and available niches in the special microclimate in villages and cities.",
    },
  ];
