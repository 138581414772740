import React from "react";
import ExplorerMode from "../ExplorerMode/ExplorerMode";
import Home from "../Home/Home";
import background from "../../utils/assets/journeymodeback.gif";
import background2 from "../../utils/assets/exmback.gif";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Selector = () => {
  let history = useHistory();
  return (
    <div>
      <div className="row" style={{ height: "100vh" }}>
        <div className="col">
          <div
            onClick={() => {
              history.push("/home");
            }}
            style={{
              height: "100%",
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                backgroundColor: "rgba(255, 255, 255,0.8)",
                height: "100%",
                alignContent: "center",
                alignItems: "center",
                color: "#1e4e9b",
                fontSize: 50,
              }}
            >
              Journey Mode
            </div>
          </div>
        </div>
        <div className="col">
          <div
            onClick={() => {
              history.push("/explore");
            }}
            style={{
              height: "100%",
              backgroundImage: `url(${background2})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{
                backgroundColor: "rgba(255, 255, 255,0.7)",
                height: "100%",
                alignContent: "center",
                alignItems: "center",
                color: "#1e4e9b",
                fontSize: 50,
              }}
            >
              Explorer Mode
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Selector;
